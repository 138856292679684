import React, { FC } from "react"

import { graphql, Link, PageProps } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTag } from "@fortawesome/free-solid-svg-icons"

import * as R from "ramda"

import Layout from "../components/layout"
import { RemarkQueryType } from "../types"

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            date
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

type QueryProps = {
  data: {
    allMarkdownRemark: RemarkQueryType<
      {
        title: string
        date: string
        tags: Array<string>
      },
      {
        slug: string
      }
    >
  }
}

type Props = PageProps & QueryProps

const TagsPage: FC<Props> = ({ data }) => {
  const tags = R.filter(
    (s) => s,
    R.uniq(
      // R.chain == flatMap
      R.chain((edge) => edge.node.frontmatter.tags)(data.allMarkdownRemark.edges)
    )
  ).sort()

  const articlesGroupedByTag = tags.map((tag) => {
    const articles = data.allMarkdownRemark.edges
      .filter((edge) => edge.node.frontmatter.tags)
      .filter((edge) => edge.node.frontmatter.tags.includes(tag))

    return {
      tag,
      articles,
    }
  })

  return (
    <Layout>
      <h1>Tags</h1>
      <p>
        {tags.map((tag) => (
          <a className="badge badge-secondary" style={{ margin: "0.4rem" }} key={tag} href={`#${tag}`}>
            <FontAwesomeIcon icon={faTag} /> {tag}
          </a>
        ))}
      </p>
      {articlesGroupedByTag.map((tagGroup) => {
        const { tag, articles } = tagGroup

        return (
          <React.Fragment key={tag}>
            <h2 id={tag}>{tag}</h2>
            <ul>
              {articles.map((article) => (
                <li key={article.node.id}>
                  <Link to={article.node.fields.slug}>{article.node.frontmatter.title}</Link>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export default TagsPage
